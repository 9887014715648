<div class="filter-details-client-container">
  <ng-container *ngIf="personalDetailsVisible">
    <span class="h3 sub-section">{{ 'personalDetails' | translate }}</span>
    <div class="filter-details-row">
      <app-filter
        [metaData]="getFilterMetaData(fields.ageRange)"
        [(value)]="clientForm.ageRange"
        [readOnly]="readOnly"
        *ngIf="clientFilters.ageRange | isFilterClientFilterActive"
      ></app-filter>
      <app-filter
        class="middle"
        [metaData]="getFilterMetaData(fields.gender)"
        [(value)]="clientForm.gender"
        [readOnly]="readOnly"
        *ngIf="
          (clientFilters.gender | isFilterClientFilterActive) && genders.length
        "
      ></app-filter>
      <app-filter
        class="client-specific-container__domicile"
        [metaData]="getFilterMetaData(fields.domiciles)"
        [(value)]="clientForm.domiciles"
        [readOnly]="readOnly"
        *ngIf="
          (clientFilters.domiciles | isFilterClientFilterActive) &&
          domiciles.length
        "
      ></app-filter>
    </div>
    <!-- DEMO-begin -->
    <div class="filter-details-row" *ngIf="isTriggerStoryPath()" style="padding-left: 1rem; width: 38rem;">
      <mat-form-field style="width: 100%;">
        <mat-label>{{ 'creditCard' | translate }}</mat-label>
        <mat-select value="online-shopping">
          <mat-option value="online-shopping">{{ 'onlineShopping' | translate }}</mat-option>
          <mat-option value="travel">{{ 'travel' | translate }}</mat-option>
          <mat-option value="concert">{{ 'concert' | translate }}</mat-option>
          <mat-option value="other">{{ 'other' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- DEMO-end -->
  </ng-container>
</div>
