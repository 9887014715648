import {Injectable} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {Campaign, CampaignAction, Product} from "../api/core";

@Injectable({
  providedIn: 'root',
})
export class DemoService {

  constructor(
    readonly sanitizer: DomSanitizer
  ) {
  }
  isHouseViewCampaign(campaign: Campaign) : Boolean {
    return campaign.name === "House View";
  }
  isDemoConversionRateCampaign(campaign: Campaign) {
    return campaign.name == "Tech Story";
  }
  getHouseViewHref() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://demo-adapter.argo.aspark.ch/pull"
    )
  }

  // DE: Die Estimated Conversion Rate wird zu Beginn mit einem bestimmten
  // Wert dargestestellt => 38%.
  // Als Highchart eignet sich zum Bespielt der Bullet graph
  // EN: The Estimated Conversion Rate is initially shown with a specific value => 38%.
  // The bullet graph is suitable as a high chart
  getConversionRate(activeFilters: number) {
    switch (activeFilters) {
      case 0:
        return 38;
      case 1:
        return 43;
      case 2:
        return 38;
      default:
        return 28;
    }
  }

  getEstimatedConversionRate(delta: number) {
    if (delta < 0) return 68;
    if (delta > 0) return 45;
    return 38;
  }

  getAttractivenessScore(campaignAction: CampaignAction, product: Product) {
    return Math.random();
  }
}
