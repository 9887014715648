<div *ngIf="product" class="page-content-container">
  <app-sub-header
    [header]="product.name"
    [breadcrumbs]="['objects', 'productOverview']"
  >
    <app-config-icon
      iconIdentifier="edit_m"
      class="color-primary"
      [tooltip]="'editProductDetails' | translate"
      (click)="editProductDetails()"
      *ngIf="protectedActions.editProduct | protectedAction"
    ></app-config-icon>
  </app-sub-header>

  <div class="aspark-container-padding">
    <app-product-details
      *ngIf="product"
      [product]="product"
    ></app-product-details>
  </div>
</div>
