<ng-container >
  <app-filter-details-operator-button
    [operator]="operator"
    (operatorChange)="changeOperator($event)"
  ></app-filter-details-operator-button>
  <ng-container *ngFor="let filter of selected">
    <div>
      <app-tag class="primary">
        <div>{{ getFilter(filter) }}</div>
        <app-config-icon
          iconIdentifier="delete"
          class="mat-icon"
          (click)="deleteFilter(filter.key)"
        ></app-config-icon>
      </app-tag>
      <mat-form-field>
        <mat-label>Elapsed time</mat-label>
        <mat-select>
          <mat-option value="1-week">1 week</mat-option>
          <mat-option value="2-weeks">2 weeks</mat-option>
          <mat-option value="3-weeks">3 weeks</mat-option>
          <mat-option value="4-weeks">4 weeks</mat-option>
          <mat-option value="2-months">2 months</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Trigger (optional)</mat-label>
        <mat-select>
          <mat-option value="trigger1">Trigger 1</mat-option>
          <mat-option value="trigger2">Trigger 2</mat-option>
          <mat-option value="trigger3">Trigger 3</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-filter-add
    *ngIf="items.length"
    [options]="items"
    (selectChange)="addFilter($event)"
  ></app-filter-add>
</ng-container>
