export enum EViewTitles {
  admin = 'admin',
  callback = 'callback',
  campaignOverview = 'campaignOverview',
  campaigns = 'campaigns',
  clientOverview = 'clientOverview',
  clients = 'clients',
  employeeOverview = 'employeeOverview',
  employees = 'employees',
  errorOverview = 'errorOverview',
  errors = 'errors',
  home = 'home',
  intermediaries = 'intermediaries',
  intermediaryOverview = 'intermediaryOverview',
  login = 'login',
  license = 'license',
  monitoringCampaign = 'monitoringCampaigns',
  monitoringCampaignDecentralized = 'monitoringDecentralizedCampaigns',
  monitoringCampaignOverview = 'monitoringCampaignOverview',
  monitoringRelationshipManager = 'monitoringRelationshipManager',
  monitorings = 'monitorings',
  playground = 'filtering',
  portfolioOverview = 'portfolioOverview',
  portfolios = 'portfolios',
  objects = 'objects',
  productOverview = 'productOverview',
  products = 'products',
  settings = 'settings',
  stories = 'stories',
  storyOverview = 'storyOverview',
  triggerStoryOverview = 'triggerStoryOverview',
  mySettings = 'mySettings',
  myDynamicUsers = 'deputies',
  myFavoriteCurrencies = 'favoriteCurrencies',
  myIntermediaryCollections = 'intermediaryCollections',
  distributor = 'distributorMonitoring',
}

export enum EViewRoutes {
  admin = '/admin',
  callback = '/callback',
  campaignOverview = '/campaign/overview/',
  campaigns = '/campaign/all',
  clientOverview = '/client/overview/',
  clients = '/client/all',
  employeeOverview = '/employee/overview/',
  employees = '/employee/all',
  errorOverview = '/error/overview/',
  errors = '/error/all',
  home = '/home',
  icons = '/icons',
  linkCreator = '/link-creator',
  intermediaries = '/intermediary/all',
  intermediaryOverview = '/intermediary/overview/',
  login = '/login',
  license = '/license',
  monitoringCampaign = '/monitoring/campaign',
  monitoringCampaignOverview = '/monitoring/campaign/overview/',
  monitoringRelationshipManager = '/monitoring/relationshipmanager/',
  monitoringCampaignDecentralized = '/monitoring/campaign/decentralized/',
  monitoringCampaignDecentralizedOverview = '/monitoring/campaign/decentralized/overview/',
  monitorings = '/monitoring',
  playground = '/simulator/playground',
  portfolioOverview = '/portfolio/overview/',
  portfolios = '/portfolio/all',
  objects = '/objects',
  objectsClients = '/objects/client/all',
  objectsEmployees = '/objects/employee/all',
  objectsIntermediaries = '/objects/intermediary/all',
  objectsPortfolios = '/objects/portfolio/all',
  objectsProducts = '/objects/product/all',
  productOverview = '/product/overview/',
  products = '/product/all',
  settings = '/settings',
  stories = '/story/all',
  storyOverview = '/story/overview/',
  triggerStoryOverview = '/demo/trigger-story-overview/',
  mySettings = '/my-settings',
  myDynamicUsers = '/my-settings/dynamic-users',
  myFavoriteCurrencies = '/my-settings/favorite-currencies',
  myIntermediaryCollections = '/my-settings/intermediary-collections',
  distributor = '/distributor',
  distributorClients = '/distributor/clients',
  distributorClientOverview = '/distributor/clients/overview/',
  distributorPortfolios = '/distributor/portfolios',
  distributorPortfolioOverview = '/distributor/portfolios/overview/',
  distributorIntermediaries = '/distributor/intermediaries',
  distributorIntermediaryOverview = '/distributor/intermediaries/overview/',
  distributorEmployees = '/distributor/employees',
  distributorEmployeeOverview = '/distributor/employees/overview/',
  mySalesforce = '/my-settings/salesforce',
}

export enum ERoutePaths {
  all = 'all',
  errorAll = 'error/all',
  errorOverview = 'error/overview/:id',
  overview = 'overview/:id',
  redirect = 'redirect',
}

export enum EViewUrlSegments {
  client = 'client',
  details = 'details',
  home = 'home',
}

export enum EAnimationStates {
  final = 'final',
  initial = 'initial',
}

export enum EModalType {
  addCampaignPortfolios = 'addCampaignPortfolios',
  addCampaignIntermediaries = 'addCampaignIntermediaries',
  campaignDetailForm = 'campaignDetailForm',
  campaignSubmit = 'campaignSubmit',
  confirmationDialog = 'confirmationDialog',
  contentDownload = 'contentDownload',
  createCampaign = 'createCampaign',
  createCampaignContent = 'createCampaignContent',
  createCustomContent = 'createCustomContent',
  createStory = 'createStory',
  createStoryContent = 'createStoryContent',
  decisionModal = 'decisionModal',
  deleteStory = 'deleteStory',
  editAuthorModal = 'editAuthorModal',
  editCampaign = 'editCampaign',
  editCampaignChannel = 'editCampaignChannel',
  editCampaignProducts = 'editCampaignProducts',
  editCampaignSender = 'editCampaignSender',
  viewCampaignChannel = 'viewCampaignChannel',
  editProduct = 'editProduct',
  editStory = 'editStory',
  editStoryProducts = 'editStoryProducts',
  escalationMail = 'escalationMail',
  launchCampaignDialog = 'launchCampaignDialog',
  marketingSuitability = 'marketingSuitability',
  mediaModal = 'mediaModal',
  monitoringCampaignFilter = 'monitoringCampaignFilter',
  pdfPreview = 'pdfPreview',
  portfolioConstruction = 'portfolioConstruction',
  portfolioDetails = 'portfolioDetails',
  portfolioFilter = 'portfolioFilter',
  previewIntermediaryPortfolios = 'previewIntermediaryPortfolios',
  productDetail = 'productDetail',
  showCampaignIntermediaryPortfolios = 'showCampaignIntermediaryPortfolios',
  productMatching = 'productMatching',
  simulator = 'simulator',
  simulatorFilter = 'simulatorFilter',
  storyDetail = 'storyDetail',
  storySelect = 'storySelect',
  storySellProductFilter = 'storySellProductFilter',
  suitabilityDetails = 'suitabilityDetails',
  suitabilitySummary = 'suitabilitySummary',
  updateCampaignContent = 'updateCampaignContent',
  updateCustomContent = 'updateCustomContent',
  updateStoryContent = 'updateStoryContent',
  viewCustomContent = 'viewCustomContent',
  previewContent = 'previewContent',
  showPortfolioPositions = 'showPortfolioPositions',
  help = 'help',
  detailsDialog = 'detailsDialog',
  executeActionsDialog = 'executeActionsDialog',
  revertActionsDialog = 'revertActionsDialog',
  setNoActionsDialog = 'setNoActionsDialog',
  removeActionsDialog = 'removeActionsDialog',
  refreshSuitabilitiesDialog = 'refreshSuitabilitiesDialog',
  assignActionsToAdvisorDialog = 'assignActionsToAdvisorDialog',
  assignActionsToRelationshipManagerDialog = 'assignActionsToRelationshipManagerDialog',
  storyInfo = 'storyInfo',
  orgPositions = 'orgPositions',
  createIntro = 'createIntro',
  createOutro = 'createOutro',
  editIntro = 'editIntro',
  editOutro = 'editOutro',
  editTemplateDefault = 'editTemplateDefault',
  salutationPreview = 'salutationPreview',
  createCollection = 'createCollection',
  editCollection = 'editCollection',
  bulkEdit = 'bulkEdit',
  pickClients = 'pickClients',
  promptText = 'promptText',
  bucketAdd = 'bucketAdd',
  bucketEdit = 'bucketEdit',
  bucketDelete = 'bucketDelete',
  gridFilter = 'gridFilter',
  addEmail = 'addEmail',
  editEmail = 'editEmail',
  deleteEmail = 'deleteEmail',
  launchCampaign = 'launchCampaign',
  searchProducts = 'searchProducts',
  clientAccessedDocuments = 'clientAccessedDocuments',
  fieldDefinitionAdd = 'fieldDefinitionAdd',
  fieldDefinitionEdit = 'fieldDefinitionEdit',
  contentDefinitionAdd = 'contentDefinitionAdd',
  contentDefinitionEdit = 'contentDefinitionEdit',
  hubChannelDefinitionMappingUpdate = 'hubChannelDefinitionMappingUpdate',
  sendToMyself = 'sendToMyself',
  appMessagesAdd = 'appMessagesAdd',
  appMessagesEdit = 'appMessagesEdit',
  appMessagesDelete = 'appMessagesDelete',
  appMessagesList = 'appMessagesList',
  sentMessagesDelete = 'sentMessagesDelete',
  updateActionDetails = 'updateActionDetails',
  targetedClients = 'targetedClients',
  demoConversionRate = 'demoConversionRate',
  demoTriggerNow = 'demoTriggerNow',
}

export enum EFilterType {
  amount,
  chips,
  countryMultiSelect,
  date,
  dateRange,
  multiSelect,
  multiSelectDropdown,
  percentage,
  range,
  rangeSlider,
  rangeWeight,
  select,
  text,
  toggle,
  autocomplete,
}

export enum EFilterSubType {
  chipsProduct,
  chipsRelationshipManager,
  rangeFromTo,
  rangeMinMax,
  rangeMinMaxPercentage,
  autoProducts,
  toggleRequired,
  chipsAdvisor,
  autoRmDepartment,
  autoIssuer
}

export enum EChartTypes {
  actionSuitabilityPie,
  assetClassBar,
  assetClassPie,
  campaignActionStatesPie,
  currencyBar,
  currencyPie,
  currentUserCampaignActionStatesPie,
  monitoringContacted3Pie,
  monitoringContactedPie,
  monitoringEarningsBar,
  monitoringExecutedPie,
  monitoringExecutedPiePerSentAction,
  monitoringHoldingLineChart,
  monitoringPortfolioRatePie,
  monitoringTradingVolumeBar,
  portfolioSuitabilityPie,
  prodAssetClassPie,
  prodRegionPie,
  prodSectorPie,
  regionBar,
  regionPie,
  scatter,
  sectorBar,
  sectorPie,
  portfolioKpi,
  actionChannelPie,
  actionLanguagePie,
  monitoringPie,
  homeParticipatedPie,
}

export enum ERangeWeightFilter {
  exposure = 'exposure',
  weight = 'weight',
}

export enum EClientFilter {
  ageRange = 'ageRange',
  domiciles = 'domiciles',
  gender = 'gender',
}

export enum EClientFilterTranslation {
  ageRange = 'age',
  domiciles = 'domiciles',
  gender = 'gender',
}

export enum EOrgPositionFilterTranslation {
  orgPosition = 'orgPosition',
}

export enum EIntermediaryFilter {
  excludeEWA = 'excludeEWA',
  excludeEAM = 'excludeEAM',
  excludeAll = 'excludeAll',
}

export enum EPortfolioFilter {
  advisoryType = 'advisoryType',
  bu = 'bu',
  businessDivision = 'businessDivision',
  fidleg = 'fidleg',
  advisor = 'advisor',
  manager = 'manager',
  mifid = 'mifid',
  model = 'model',
  portfolioType = 'portfolioType',
  qualifiedInvestor = 'qualifiedInvestor',
  referenceCurrency = 'referenceCurrency',
  returnRange = 'returnRange',
  riskRange = 'riskRange',
  rmDepartment = 'rmDepartment',
  rmLocation = 'rmLocation',
  rmMarket = 'rmMarket',
  serviceCenter = 'serviceCenter',
  sustainabilityProfile = 'sustainabilityProfile',
  valueRange = 'valueRange',
  waiveOfAdvice = 'waiveOfAdvice',
  liquidityRange = 'liquidityRange',
  riskBreachOnly = 'riskBreachOnly',
  excludeRiskBreach = 'excludeRiskBreach',
  allowOptOut = 'allowOptOut',
}

export enum EIntermediaryFilterTranslation {
  excludeEWA = 'intermediaryExcludeEWA',
  excludeEAM = 'intermediaryExcludeEAM',
  excludeAll = 'intermediaryExcludeAll',
}

export enum EPortfolioFilterTranslation {
  advisoryType = 'advisoryType',
  bu = 'businessUnit',
  businessDivision = 'businessDivision',
  fidleg = 'fidlegClientSegmentation',
  advisor = 'advisor',
  manager = 'relationshipManager',
  mifid = 'mifidClientSegmentation',
  model = 'riskProfile',
  portfolioType = 'portfolioType',
  qualifiedInvestor = 'qualifiedInvestor',
  referenceCurrency = 'referenceCurrency',
  returnRange = 'return',
  riskRange = 'risk',
  rmDepartment = 'rmDepartment',
  rmLocation = 'rmLocation',
  rmMarket = 'rmMarket',
  serviceCenter = 'serviceCenter',
  sustainabilityProfile = 'sustainabilityProfile',
  valueRange = 'portfolioValue',
  liquidityRange = 'liquidity',
  waiveOfAdvice = 'waiveOfAdvice',
  riskBreachOnly = 'riskBreachOnly',
  excludeRiskBreach = 'excludeRiskBreach',
  allowOptOut = 'includeOptOutClients',
}

export enum EPositionFilter {
  assetClass = 'assetClass',
  assetSubClass = 'assetSubClass',
  assetType = 'assetType',
  excludeIds = 'excludeIds',
  ids = 'ids',
  nextCallDate = 'nextCallDate',
  ranking = 'ranking',
  ratingMoody = 'ratingMoody',
  ratingSourceLGT = 'ratingSourceLGT',
  ratingSP = 'ratingSP',
  sustainabilityRating = 'sustainabilityRating',
  maturityDate = 'maturityDate',
  value = 'value',
  productRatingApac = 'productRatingApac',
  productRatingMe = 'productRatingMe',
  productInvestmentHorizon = 'productInvestmentHorizon',
  issuerName = 'issuerName',
}

export enum EPositionFilterTranslation {
  assetClass = 'assetClass',
  assetSubClass = 'assetSubClass',
  assetType = 'assetType',
  bestPerformance = 'performanceBetterThan',
  // excludedIds = 'assets(Excluded)', // DEMO-only
  excludedIds = 'assetsInPortfolio', // DEMO-only
  ids = 'assets(Available)',
  nextCallDate = 'nextCallDate',
  ranking = 'ranking',
  ratingMoody = 'moodyRating',
  ratingSourceLGT = 'ratingSource',
  ratingSP = 's&pRating',
  sustainabilityRating = 'sustainabilityRating',
  maturityDate = 'maturityDate',
  value = 'positionValue',
  productRatingApac = 'productRatingApac',
  productRatingMe = 'productRatingMe',
  productInvestmentHorizon = 'productInvestmentHorizon',
  issuerName = 'issuerName',
}

export enum EFilterCategories {
  advanced = 'advanced',
  assets = 'assets',
  assetClass = 'assetClass',
  client = 'client',
  intermediaries = 'intermediaries',
  currency = 'currency',
  portfolio = 'portfolio',
  position = 'position',
  region = 'region',
  sector = 'sector',
  orgPosition = 'orgPosition',
  demoProducts = 'products',
}

export enum EFilterHeaderActionType {
  edit,
  none,
}

export enum EAdditionalCardState {
  assetClass = 'assetClass',
  charts = 'charts',
  currency = 'currency',
  portfolios = 'portfolios',
  region = 'region',
  sector = 'sector',
}

export enum EGroupFilterSelectStatus {
  all,
  none,
  some,
}

export enum EApiCountryKey {
  AFG = 'af',
  ALB = 'al',
  DZA = 'dz',
  AND = 'ad',
  AGO = 'ao',
  ATG = 'ag',
  ARG = 'ar',
  ARM = 'am',
  AUS = 'au',
  AUT = 'at',
  AZE = 'az',
  BHS = 'bs',
  BHR = 'bh',
  BGD = 'bd',
  BRB = 'bb',
  BLR = 'by',
  BEL = 'be',
  BLZ = 'bz',
  BEN = 'bj',
  BTN = 'bt',
  BOL = 'bo',
  BIH = 'ba',
  BWA = 'bw',
  BRA = 'br',
  BRN = 'bn',
  BGR = 'bg',
  BFA = 'bf',
  BDI = 'bi',
  KHM = 'kh',
  CMR = 'cm',
  CAN = 'ca',
  CPV = 'cv',
  TCD = 'td',
  CHL = 'cl',
  CHN = 'cn',
  COL = 'co',
  COM = 'km',
  COD = 'cd',
  CRI = 'cr',
  HRV = 'hr',
  CUB = 'cu',
  CYP = 'cy',
  CZE = 'cz',
  DNK = 'dk',
  DJI = 'dj',
  DMA = 'dm',
  DOM = 'do',
  ECU = 'ec',
  EGY = 'eg',
  SLV = 'sv',
  GNQ = 'gq',
  ERI = 'er',
  EST = 'ee',
  SWZ = 'sz',
  ETH = 'et',
  FJI = 'fj',
  FIN = 'fi',
  FRA = 'fr',
  GAB = 'ga',
  GMB = 'gm',
  GEO = 'ge',
  DEU = 'de',
  GHA = 'gh',
  GRC = 'gr',
  GRD = 'gd',
  GTM = 'gt',
  GIN = 'gn',
  GNB = 'gw',
  GUY = 'gy',
  HTI = 'ht',
  HND = 'hn',
  HKG = 'hk',
  HUN = 'hu',
  ISL = 'is',
  IND = 'in',
  IDN = 'id',
  IRN = 'ir',
  IRQ = 'iq',
  IRL = 'ie',
  ISR = 'il',
  ITA = 'it',
  CIV = 'ci',
  JAM = 'jm',
  JPN = 'jp',
  JOR = 'jo',
  KAZ = 'kz',
  KEN = 'ke',
  KIR = 'ki',
  KWT = 'kw',
  KGZ = 'kg',
  LAO = 'la',
  LVA = 'lv',
  LBN = 'lb',
  LSO = 'ls',
  LBR = 'lr',
  LBY = 'ly',
  LIE = 'li',
  LTU = 'lt',
  LUX = 'lu',
  MAC = 'mo',
  MKD = 'mk',
  MDG = 'mg',
  MWI = 'mw',
  MYS = 'my',
  MDV = 'mv',
  MLI = 'ml',
  MLT = 'mt',
  MHL = 'mh',
  MRT = 'mr',
  MUS = 'mu',
  MEX = 'mx',
  MDA = 'md',
  MCO = 'mc',
  MNG = 'mn',
  MNE = 'me',
  MAR = 'ma',
  MOZ = 'mz',
  MMR = 'mm',
  NAM = 'na',
  NRU = 'nr',
  NPL = 'np',
  NLD = 'nl',
  NCL = 'nc',
  NZL = 'nz',
  NIC = 'ni',
  NER = 'ne',
  NGA = 'ng',
  PRK = 'kp',
  NOR = 'no',
  OMN = 'om',
  PAK = 'pk',
  PLW = 'pw',
  PSE = 'ps',
  PAN = 'pa',
  PNG = 'pg',
  PRY = 'py',
  PER = 'pe',
  PHL = 'ph',
  POL = 'pl',
  PRT = 'pt',
  QAT = 'qa',
  ROU = 'ro',
  RUS = 'ru',
  RWA = 'rw',
  KNA = 'kn',
  LCA = 'lc',
  VCT = 'vc',
  WSM = 'ws',
  SMR = 'sm',
  STP = 'st',
  SAU = 'sa',
  SEN = 'sn',
  SRB = 'rs',
  SYC = 'sc',
  SLE = 'sl',
  SGP = 'sg',
  SVK = 'sk',
  SVN = 'si',
  SLB = 'sb',
  SOM = 'so',
  ZAF = 'za',
  KOR = 'kr',
  SSD = 'ss',
  ESP = 'es',
  LKA = 'lk',
  SDN = 'sd',
  SUR = 'sr',
  SWE = 'se',
  CHE = 'ch',
  SYR = 'sy',
  TWN = 'tw',
  TJK = 'tj',
  TZA = 'tz',
  THA = 'th',
  TLS = 'tl',
  TGO = 'tg',
  TON = 'to',
  TTO = 'tt',
  TUN = 'tn',
  TUR = 'tr',
  TKM = 'tm',
  TUV = 'tv',
  UGA = 'ug',
  UKR = 'ua',
  ARE = 'ae',
  GBR = 'gb',
  USA = 'us',
  URY = 'uy',
  UZB = 'uz',
  VUT = 'vu',
  VEN = 've',
  VNM = 'vn',
  YEM = 'ye',
  ZMB = 'zm',
  ZWE = 'zw',
}

export enum EApiAssetType {
  Bond = 'bond',
  Cash = 'cash',
  Etf = 'etf',
  Fund = 'fund',
  Share = 'share',
  Stock = 'stock', // TODO Can this be removed? Looks like that it has been replaced by "share"
}

export enum ESuitabilityStatus {
  ok = 'ok',
  warning = 'warning',
  fail = 'not ok',
}

export enum StoryProductType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum EFormStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export enum ECampaignStatusAction {
  close = 'close',
  delete = 'delete',
  freeze = 'freeze',
  launch = 'launch',
  relaunch = 'relaunch',
  terminate = 'terminate',
  unfreeze = 'unfreeze',
  unlaunch = 'unlaunch',
}

export enum EPortfolioActionStatus {
  duplicate_client = 'DUPLICATE_CLIENT',
  no_action = 'NO_ACTION',
  pending = 'PENDING',
  sent = 'SENT',
}

export enum ECodeTables {
  advisoryType = 'advisoryType',
  assetClass = 'assetClass',
  assetSubClass = 'assetSubClass',
  assetType = 'assetType',
  businessDivision = 'businessDivision',
  businessUnit = 'businessUnit',
  channelType = 'channelType',
  clientRole = 'clientRole',
  clientType = 'clientType',
  continent = 'continent',
  country = 'country',
  currency = 'currency',
  fidlegClientSegmentation = 'fidlegClientSegmentation',
  gender = 'gender',
  gics = 'gics',
  hobby = 'hobby',
  hub = 'hub',
  language = 'language',
  mifidClientSegmentation = 'mifidClientSegmentation',
  portfolioStrategy = 'portfolioStrategy',
  portfolioType = 'portfolioType',
  productInvestmentHorizon = 'productInvestmentHorizon',
  productRatingApac = 'productRatingApac',
  productRatingMe = 'productRatingMe',
  publicationType = 'publicationType',
  ranking = 'ranking',
  rating = 'rating',
  ratingMoody = 'ratingMoody',
  ratingSource = 'ratingSource',
  ratingSp = 'ratingSp',
  ratingSustainability = 'ratingSustainability',
  region = 'region',
  relAdvisor = 'relAdvisor',
  relManager = 'relManager',
  riskState = 'riskState',
  rmMarket = 'rmMarket',
  serviceCenter = 'serviceCenter',
  sustainabilityProfile = 'sustainabilityProfile',
  useCase = 'useCase',
  viewedStatus = 'viewedStatus',
}

export enum EFormValidators {
  textFieldMaxLength = 1000,
  // [\s\S]* any character (including new lines) 0 or more times; \S+ any non-whitespace character 1 or more times
  textFieldNotBlankPattern = '[\\s\\S]*\\S+[\\s\\S]*',
}

export enum EOverviewTabs {
  details = 'details',
  contentAndProducts = 'contentAndProducts',
  allPortfoliosAndClients = 'allPortfoliosAndClients',
  allowedUsers = 'allowedUsers',
}

export enum ESettingsTabs {
  summary,
  users,
  salutations,
  templateDefaults,
}

export enum EObjectOverviewTabs {
  clients,
  portfolios,
  products,
}

export enum EMySettingsOverviewTabs {
  collections,
  dynamicUsers,
}

export enum EPortfolioOverviewTabs {
  details,
  campaigns,
  clients,
  positions,
}

export enum EClientOverviewTabs {
  details,
  actions,
  portfolios,
  campaigns,
}

export enum EHelpContentType {
  image = 'image',
  paragraph = 'paragraph',
  header = 'header',
  subheader = 'subheader',
  icon = 'icon',
}

export enum EMonitoringTypes {
  campaign,
  relationshipManager,
}

export enum EGridFilterType {
  date = 'date',
  numeric = 'numeric',
  set = 'set',
  text = 'text',
}

export enum EReferenceType {
  story = 'STORY',
  campaign = 'CAMPAIGN',
}
