<div class="header-actions-wrapper" [formGroup]="formFields">
  <div class="minSuitability">
    <mat-form-field>
      <mat-label>{{ 'smartNotificationsMinSuitabilityLevel' | translate }}</mat-label>
      <mat-select [(value)]="suitability" formControlName="suitability">
        <mat-select-trigger>
          <span *ngIf="suitability" class="icon-with-text">
            <app-config-icon [iconIdentifier]="suitability.icon" [ngClass]="suitability.color"/>
            {{suitability.value | translate}}
          </span>
        </mat-select-trigger>
        <mat-option value="">Select an item...</mat-option>
        <mat-option *ngFor="let it of suitabilities" [value]="it">
          <span style="display: flex; flex-direction: row; align-items: center; gap: 1rem">
            <app-config-icon [iconIdentifier]="it.icon" [ngClass]="it.color"/>
            {{it.value | translate}}
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="toggleTitle">
    <span>Fund Reference Currency Matching</span>
  </div>
  <div class="toggle">
    <mat-slide-toggle
      [checked]="fundRef"
      color="primary"
      formControlName="fundRef"
    />
  </div>
  <div class="scoreTitle">
    <span>{{ 'demoScoreMatching' | translate }}</span>
  </div>
  <div class="scoreToggle">
    <mat-slide-toggle
      [checked]="scoreMatching"
      color="primary"
      formControlName="scoreMatching"
    />
  </div>
  <div class="apply">
    <button
      mat-flat-button
      color="primary"
      (click)="applyFundMatching()"
    >
      {{'apply' | translate}}
    </button>
  </div>
</div>

<app-card class="available">
  <div card-header>
    Story ISINs
  </div>
  <div card-body>
    <app-grid
      tableId="client-picker-available"
      [rowData]="buyProducts"
      [columnDefs]="productsColDefs"
      [gridOptions]="gridOptions"
    ></app-grid>
  </div>
</app-card>

<app-card class="selected">
  <div card-header>
    ISINs matched to the client
  </div>
  <div card-body>
    <app-grid
      tableId="client-picker-selected"
      [rowData]="selected"
      [columnDefs]="selectedColDefs"
      [gridOptions]="selectedGridOptions"
    ></app-grid>
  </div>
</app-card>

<div class="actions">
  <button
    mat-stroked-button
    color="primary"
    [disabled]="!canAdd()"
    (click)="addSelection()"
    aria-label="Add Selected Clients"
  >
    <app-config-icon iconIdentifier="keyboard_arrow_right"></app-config-icon>
  </button>
</div>
