<ng-container >
  <app-filter-details-operator-button
    [operator]="operator"
    (operatorChange)="changeOperator($event)"
  ></app-filter-details-operator-button>
  <ng-container *ngFor="let filter of selected">
    <div>
      <app-tag class="primary">
        <div>{{ getFilter(filter) }}</div>
        <app-config-icon
          iconIdentifier="delete"
          class="mat-icon"
          (click)="deleteFilter(filter.key)"
        ></app-config-icon>
      </app-tag>
      <mat-form-field>
        <mat-label>As</mat-label>
        <mat-select [value]="getDefaultValue(filter.key)">
          <mat-option value="client-has-product">{{ 'clientHasProduct' | translate }}</mat-option>
          <mat-option value="client-does-not-have-product">{{ 'clientDoesntHaveProduct' | translate }}</mat-option>
          <mat-option value="client-does-not-use-product">{{ 'clientDoesntUseProduct' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <app-filter-add
    *ngIf="items.length"
    [options]="items"
    (selectChange)="addFilter($event)"
  ></app-filter-add>
</ng-container>
