import {Component, Inject, LOCALE_ID, OnInit,} from '@angular/core';
import {INestedMenuItem} from 'src/app/models/menu.model';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {CodeTableService} from "../../../services/code-table.service";

/**
 * DEMO: Filter details for product, used in trigger stories
 */
@Component({
  selector: 'app-demo-filter-details-product',
  templateUrl: './demo-filter-details-product.component.html',
})
export class DemoFilterDetailsProductComponent implements OnInit {
  operator = 'mor';
  selected: INestedMenuItem[] = [];
  items: INestedMenuItem[] = [
    {
      key: 'bankingProducts',
      label: 'Banking Products',
      children: [
        {
          key: 'accounts',
          label: 'Accounts',
          children: [
            {
              key: 'currentAccount',
              label: 'Current Account',
            },
            {
              key: 'privateAccount',
              label: 'Private Account',
            },
            {
              key: 'savingAccount',
              label: 'Saving Account',
            },
          ],
        },
        {
          key: 'cards',
          label: 'Cards',
          children: [
            {
              key: 'debitCards',
              label: 'Debit Cards',
              children: [
                {
                  key: 'visaDebit',
                  label: 'Visa Debit',
                },
                {
                  key: 'maestroDebit',
                  label: 'Maestro Debit',
                },
              ],
            },
            {
              key: 'creditCards',
              label: 'Credit Cards',
              children: [
                {
                  key: 'visa',
                  label: 'Visa',
                  children: [
                    {
                      key: 'visaGold',
                      label: 'Visa Gold'
                    },
                    {
                      key: 'visaPlatinum',
                      label: 'Visa Platinum'
                    }
                  ]
                },
                {
                  key: 'mastercard',
                  label: 'Mastercard',
                  children: [
                    {
                      key: 'mastercardGold',
                      label: 'Mastercard Gold'
                    },
                    {
                      key: 'mastercardPlatinum',
                      label: 'Mastercard Platinum'
                    }
                  ]
                }
              ],
            },
          ],
        },
      ],
    },
    {
      key: 'loans',
      label: 'Loans',
      children: [
        {
          key: 'mortgage',
          label: 'Mortgage',
          children: [
            {
              key: 'fixedRate',
              label: 'Fixed Rate',
            },
            {
              key: 'variableRate',
              label: 'Variable Rate',
            },
            {
              key: 'saron',
              label: 'Saron',
            }
          ],
        },
        {
          key: 'lombard',
          label: 'Lombard',
        }
      ],
    },
    {
      key: 'investmentSolutions',
      label: 'Investment Solutions',
      children: [
        {
          key: 'advisory',
          label: 'Advisory',
          children: [
            {
              key: 'consult',
              label: 'Consult',
            },
            {
              key: 'excellence',
              label: 'Excellence',
            },
            {
              key: 'excellencePlus',
              label: 'Excellence Plus',
            },
          ]
        },
        {
          key: 'discretionary',
          label: 'Discretionary',
          children: [
            {
              key: 'sustainable',
              label: 'Sustainable',
              children: [
                {
                  key: 'income',
                  label: 'Income',
                },
                {
                  key: 'balanced',
                  label: 'Balanced',
                },
                {
                  key: 'growth',
                  label: 'Growth',
                },
                {
                  key: 'equity',
                  label: 'Equity',
                }
              ],
            },
            {
              key: 'classic',
              label: 'Classic',
              children: [
                {
                  key: 'income',
                  label: 'Income',
                },
                {
                  key: 'balanced',
                  label: 'Balanced',
                },
                {
                  key: 'growth',
                  label: 'Growth',
                },
                {
                  key: 'equity',
                  label: 'Equity',
                }
              ]
            }
          ]
        },
      ]
    },
    {
      key: 'pension',
      label: 'Pension',
      children: [
        {
          key: 'financialPlanning',
          label: 'Financial Planning',
        },
        {
          key: 'pensionAccount',
          label: 'Pension Account',
        },
        {
          key: 'pensionInvestments',
          label: 'Pension Investments',
        }
      ],
    }
  ];

  constructor(
    protected globalService: GlobalService,
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    @Inject(LOCALE_ID) protected locale: string
  ) {
  }

  ngOnInit(): void {
  }

  addFilter(item: INestedMenuItem): void {
    // avoid adding items with children
    if (item.children && item.children.length > 0) {
      return;
    }
    this.selected.push({
      key: item.key,
    });
    // remove added key from available keys
  }

  deleteFilter(key: string): void {
    const index = this.selected.findIndex((filter) => filter.key === key);
    this.selected.splice(index, 1);
  }

  changeOperator($event: string) {
    $event === 'and' ? this.operator = 'and' : this.operator = 'mor';
  }

  getFilter(filter: INestedMenuItem) {
    const flattened = this.flatten(this.items);
    return flattened.find(d => d.key === filter.key).label;
  }

  // flatten a nested array with its children
  private flatten(ary: INestedMenuItem[], ret: INestedMenuItem[] = []) {
    return ary.reduce((ret, entry) => {
      if (Array.isArray(entry.children)) {
        this.flatten(entry.children, ret);
      } else {
        ret.push(entry);
      }
      return ret;
    }, ret);
  }


  getDefaultValue(key: string) {
    if (['visaGold', 'visaPlatinum'].includes(key)) {
      return 'client-does-not-use-product';
    }
    return undefined;
  }
}
