import {Component, Inject, LOCALE_ID, OnInit,} from '@angular/core';
import {INestedMenuItem} from 'src/app/models/menu.model';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {CodeTableService} from "../../../services/code-table.service";
import {CollectionService} from "../../../api/core";

/**
 * DEMO: Filter details for product, used in trigger stories
 */
@Component({
  selector: 'app-demo-filter-details-marketing-link',
  templateUrl: './demo-filter-details-marketing-link.component.html',
})
export class DemoFilterDetailsMarketingLinkComponent implements OnInit {
  operator = 'mor';
  selected: INestedMenuItem[] = [];
  items: INestedMenuItem[] = [];

  constructor(
    protected globalService: GlobalService,
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    private collectionService: CollectionService,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    collectionService.getCollections().subscribe((data) => {
      this.items = data.map(d => ({key: d.id.toString(), label: d.name}))
    });
  }

  ngOnInit(): void {
  }

  addFilter(item: INestedMenuItem): void {
    // avoid adding items with children
    if (item.children && item.children.length > 0) {
      return;
    }
    this.selected.push({
      key: item.key,
    });
  }

  deleteFilter(key: string): void {
    const index = this.selected.findIndex((filter) => filter.key === key);
    this.selected.splice(index, 1);
  }

  changeOperator($event: string) {
    $event === 'and' ? this.operator = 'and' : this.operator = 'mor';
  }

  getFilter(filter: INestedMenuItem) {
    const flattened = this.flatten(this.items);
    return flattened.find(d => d.key === filter.key).label;
  }

  // flatten a nested array with its children
  private flatten(ary: INestedMenuItem[], ret: INestedMenuItem[] = []) {
    return ary.reduce((ret, entry) => {
      if (Array.isArray(entry.children)) {
        this.flatten(entry.children, ret);
      } else {
        ret.push(entry);
      }
      return ret;
    }, ret);
  }


}
