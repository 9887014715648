<ng-container >
  <app-filter-details-operator-button
    [operator]="operator"
    (operatorChange)="changeOperator($event)"
  ></app-filter-details-operator-button>
  <ng-container *ngFor="let filter of selected">
    <div>
      <app-tag class="primary">
        <div>{{ getFilter(filter) }}</div>
        <app-config-icon
          iconIdentifier="delete"
          class="mat-icon"
          (click)="deleteFilter(filter.key)"
        ></app-config-icon>
      </app-tag>
    </div>
  </ng-container>

  <app-filter-add
    *ngIf="items.length"
    [options]="items"
    (selectChange)="addFilter($event)"
  ></app-filter-add>
</ng-container>
